import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BannerForm from '../components/BannerForm';
const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
const RubikCube = () => {

  return (
    <div className="page-wrapper">
   
    
    <div className="banner">
   <BannerForm/>
      <div className="banner-text">
        <h3>Even the Hardest puzzles 
          <span>can be solved</span>
        </h3>
      </div>
      <div className="banner-img">
        <img src="/images/rubikcube/banner.png" alt="banner" />
      </div>
    </div>
    <section className='quotation'>
     <div className='cube-box'>
         <img src="/images/rubikcube/cube.png" alt=""/>
     </div>
        <div className="row">
            <div className="col-md-6 pd-0">
              <div className='green-box box-s'>
              <div className='inner-width-green'>
              <h1>43 Quintillion combination, but one solution.</h1>
              </div>

                  
              </div>
              <div className="orange-box box-s">
              <div className="what-u-getlist  wt">
            <h3 className="text-left font2 ">WHAT YOU GET?</h3>
            <ul>
              <li>Rubik Cube Joining Kit</li>
              <li>Live Coach / Mentor</li>
              <li>Live Online Sessions</li>
              <li>Certification </li>
            </ul>
          </div>
              </div>
            </div>
            <div className="col-md-6 pd-0">
            <div className='red-box box-s2'>
              <div className='abt-p-content'>
                <div className='abt-c-item'>
                  <div className='num'>
1
                  </div>
                  <div className='con'>
<p>The original classic Rubik Cube is a 3 Dimension combination puzzle invented by Hungarian sculptor and architect Erno Rubik. Rubik Cube has over 43 quintillion combinations which make this colorful puzzle a thrill for solving. 

</p>
                  </div>
                </div>
                <div className='abt-c-item'>
                  <div className='num'>
2
                  </div>
                  <div className='con'>
<p>At Suprkid, the live and interactive rubik’s cube solving online model is easy to understand. Under the expert’s guidance rubik’s cube classes provides step by step detail breakdown of how to solve the Rubik Cube. Children have opportunity to explore the cube by their own which will improve their executive functioning. At Suprkid, we ensure by the end of the course children are confident and solves the rubik cube independently.

</p>
                  </div>
                </div>
                <div className='abt-c-item'>
                  <div className='num'>
3
                  </div>
                  <div className='con'>
<p>A 3*3 Rubik has each of the six faces covered by nine stickers, each of one of six solid colours: white, red, blue, orange, green, and yellow. Mastering the Rubik’s cube will not only leave your child feeling accomplished but will help make them smarter & shaper. With its origins tracing back to 1974, the Rubik’s Cube was originally also called the ‘Magic Cube’.

</p>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </div>
    </section>
    {/* <section className="whatuget">
      <div className="whatuget-content">
        <div className="top-left-border" />
        <div className="whatuget-content-left">
          <h1>join India’s smartest</h1>
          <h3>Abacus Program</h3>
          <p>Suprkid Abacus Program is designed to ensure children are quick and confident in math problem solving. We provide the best and result oriented child friendly abacus training. Program is designed for kids to understand and learn mathematics fun way. 
          </p>
          <p> Our mentors ensure that your child would quick to do calculations mentally, especially the ones with a mathematical edge. </p>
          <div className="what-u-getlist mt20 ">
            <h3 className="text-left font2 mt10 wtg">WHAT YOU GET?</h3>
            <ul>
              <li>Abacus Joining Kit</li>
              <li>Live Coach / Mentor</li>
              <li>Live Online Sessions</li>
              <li>Assessments </li>
              <li>Certification</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="whatuget-middle">
        <div className="top-middle-border" />
        <img src="/images/mind-1.png" alt=''/>
      </div>
      <div className="whatuget-img">
        <div className="top-right-border" />
        <div className="box-img">
          <img src="/images/child-1.png" alt='' />
        </div>
      </div>
    </section> */}
    <section className="const-section rbk-sec">
      <div className="side-image top37">
        <img src="/images/rubikcube/design-elements.png" alt="" />
      </div>
      <div className="constation-row row">
     
        <div className="col-lg-8 col-md-12 const-middle kid-big-title">
          <div className="quick-box">
           <h3>why kids should learn the Rubik cube?</h3>
            
            <div className="bottom-image kid-big">
              <img src="/images/rubikcube/kidpic.png" alt="" />
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-12 const-last">
          <div className="program-box">
            <h3 className="mb15">Program Benefits:</h3>
            <ul>
            <li>Improves hand-eye-mind coordination</li>
            <li>Increases competitive spirit</li>
            <li>Enhance Memory skills</li>
            <li>Boost Thinking</li>
            <li>Develop Both Brain Activity</li>
            <li>Improved Colour Visualization</li>
            <li>Increased Spatial Awareness</li>
            <li>Patience and strategic thinking</li>
              
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="consultation-wrapper">
      <h3 className="mb40">How would Suprkid RUBIK CUBE Program help your Kids:</h3>
      <div className="help-row bold-f">
        <div className="help-box">
          <p>Revolving between the various possibilities and creating impromptu strategies </p>
        </div>
        <div className="help-box">
          <p>Strengthen children’s concentration </p>
        </div>
        <div className="help-box">
          <p>Increase their speed of focussed thoughts.</p>
        </div>
        <div className="help-box">
          <p>Enhance Self - Discipline and focus</p>
        </div>
        <div className="help-box">
          <p>Faster Problem Solving Skills</p>
        </div>
      </div>
    </section>
    
    <section className="portfolio-box">
      <h3 className="mb30">Glimpse of Rubik cube batch</h3>
      <div className="portfolio">
      <Carousel responsive={responsive}>
      <div>
              <img src={process.env.PUBLIC_URL + '/images/rubikcube/pf-1.png'} alt='slide' />
             
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/rubikcube/pf-2.png'} alt='slide' />
             
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/rubikcube/pf-3.png'} alt='slide' />
             
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/rubikcube/pf-1.png'} alt='slide' />
             
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/rubikcube/pf-2.png'} alt='slide' />
             
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/rubikcube/pf-3.png'} alt='slide' />
             
            </div>

</Carousel>
      
      </div>
       
    </section>
    <div className='kid_section'>
        <div className='container'>
          <div className='kid_image'>
            <img src={process.env.PUBLIC_URL + "/images/kid.png"} alt="kid" className='img-resp'/>
          </div>
        </div>

      </div>
  </div>
  )
}

export default RubikCube