import React,{useState} from 'react'
import '../assets/css/landing.css'
import { Link, useLocation } from 'react-router-dom'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const Landing = () => {
  let location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const toogleMenu = () =>{
    setOpen(!isOpen);
  }
  const scroll = () => {
    const section = document.querySelector('#explore_program');
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };
  return (
    <div className='landing-wrapper'>
      <header>
        <div className="header-top">
          <div className="top-section">
            <div className="top-box-left">
              <ul>
                <li><Link to="#">Home <span>&gt;</span></Link></li>
                <li><Link to="#">Activities</Link></li>
              </ul>
            </div>
            <div className="top-box-right">
              <Link to="/contact-us">Contact Us</Link>
               <button className={isOpen ? 'menubutton2 opened' : 'menubutton2'} onClick={toogleMenu}><span /><span /><span /></button>
            </div>
          </div>
          <div className="menu2">
          <ul className={isOpen ? 'opened' : ''}>
            <li><Link to="/abacus" className={location.pathname === '/' && "active"}>ABACUS</Link></li>
            <li><Link to="/chess" className={location.pathname === '/chess' && "active"}>CHESS</Link></li>
            <li><Link to="/martial-arts" className={location.pathname === '/martial-arts' && "active"}>martial arts</Link></li>
            <li><Link to="/rubik-cube" className={location.pathname === '/rubik-cube' && "active"}>rubik cube</Link></li>
            <li><Link to="#">Dance</Link></li>
            <li><Link to="#">vedic math</Link></li>
            <li><Link to="#">SoduKu</Link></li>
          </ul>
        </div>
        </div>
      </header>
      <div className='landing-banner'>
        <div className='container'>
          <div className='lBanner-content'>

            <div className='row'>

              <div className=' col-md-6 col-sm-6 col-12'>

                <div className='lBanner-text'>
                  <div className='lBrand'>
                    <Link to="#">
                      <img src="/images/landing/logo.png" alt="logo" />
                    </Link>
                  </div>
                  <h1>
                    unlock a
                    <span> brilliant </span>
                    future for
                    your kids
                    {/* profile */}
                  </h1>
                </div>
              </div>
              <div className='col-md-6 col-sm-6 col-12'>
                <div className='lBanner-img'>
                  <img src="/images/landing/bnr-r.png" alt="img " />
                </div>
              </div>
            </div>
            <div className='explore-btn'>
              <Link to="#" onClick={scroll}>explore activities</Link>
            </div>
          </div>
        </div>

      </div>
      <div className='brn-bottom'>
        <img src="/images/landing/bnr-bottom.png" alt="img " />
      </div>
      <div className='landing-about'>
        <div className='labt-wrap'>
          <div className='box box1'>
            <img src="/images/landing/abt.png" alt="img " />
          </div>
          <div className='box box2'>
            <h2>About <span>SuprKid</span> </h2>
            <ul>
              <li>
                <div className="list-item-ld">
                  <img src="/images/landing/right-trangle.png" alt="img " />
                  <p> SUPRKID Kid is a program designed for child's holistic development to help each child reach their full potential. It provides a complete package of customized and effective training program to help children to become fit and healthy.</p>
                </div>
              </li>


              <li>
                <div className="list-item-ld">
                  <img src="/images/landing/right-trangle.png" alt="img " />
                  <p>  Our aim is to teach various skills and techniques to bring the best out of children.</p>
                </div>
              </li>


              <li>
                <div className="list-item-ld">
                  <img src="/images/landing/right-trangle.png" alt="img " />
                  <p>   Exposure to co-curricular activities like Martial Arts, Rubik’s, Abacus, Dance, Music, Vedic Math, etc. along with a gamified approach towards learning</p>
                </div>
              </li>


              <li>
                <div className="list-item-ld">
                  <img src="/images/landing/right-trangle.png" alt="img " />
                  <p>Learn essential fitness and wellness concepts to follow an active and healthy lifestyle.</p>
                </div>
              </li>


              <li>
                <div className="list-item-ld">
                  <img src="/images/landing/right-trangle.png" alt="img " />
                  <p>Develop Leadership Skills.</p>
                </div>
              </li>


            </ul>
          </div>
        </div>
        <div className='labt-ballon'>
          <img src="/images/landing/abt-baloon.png" alt="img" />
        </div>
      </div>
      <div className='ld-why-sk'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-5 col-sm-5 col-12'></div>
            <div className='col-md-7 col-sm-7 col-12'>
              <div className='wht-box'>
                <div className='wht-box-title'>
                  <h2>Why Supr Kid? </h2>
                </div>

                <ul>
                  <li>
                    <div className="list-item-wh">
                      <img src="/images/landing/bd-1.png" alt="img " />
                      <div>
                        <h3 className='clr-1'>Experience :</h3>
                        <p>We have cultivated our programs, after years of experience and research</p>
                      </div>
                    </div>
                  </li>
                  <li className='ml'>
                    <div className="list-item-wh ">
                      <img src="/images/landing/bd-2.png" alt="img " />
                      <div>
                        <h3 className='clr-2'>Values : </h3>
                        <p>Our programs are made taking the Indian Family into account. Thus some of our courses involve family participation and ensure a better bond with the family:</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="list-item-wh">
                      <img src="/images/landing/bd-3.png" alt="img " />
                      <div>
                        <h3 className='clr-3'>Transparency : </h3>
                        <p>You choose, we provide. To us, our clients come first. So no mandatories and no hidden
                          clauses. </p>
                      </div>
                    </div>
                  </li>

                  <li className='ml'>
                    <div className="list-item-wh ">
                      <img src="/images/landing/bd-4.png" alt="img " />
                      <div>
                        <h3 className='clr-4'>Our Associates : </h3>
                        <p>Our associations help bring an unmatched level of content and creatitvity. Some of our associates - Indian Institute of Martial arts, Genius Kids </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="list-item-wh">
                      <img src="/images/landing/bd-5.png" alt="img " />
                      <div>
                        <h3 className='clr-5'>Wholesome : </h3>
                        <p>We do not focus on just one aspect of growth, but bring all together, in our packages. </p>
                      </div>
                    </div>
                  </li>
                  <li className='ml'>
                    <div className="list-item-wh ">
                      <img src="/images/landing/bd-6.png" alt="img " />
                      <div>
                        <h3 className='clr-6'>Flexible : </h3>
                        <p>Flexible and customized packages that cater to your school as per your need </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="list-item-wh mx637">
                      <img src="/images/landing/bd-7.png" alt="img " />
                      <div>
                        <h3 className='clr-7'>Laurels for your school : </h3>
                        <p>Our program has ensured that students have cultivated skills that have helped them win inter-school competitions, bringing laurels to their school. </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>


            </div>
          </div>

        </div>
        <div className='container-fluid'>
          <div className='wh-botton-wrap'>
            <div className='row'>
              <div className='col-md-7 col-sm-7 col-12'>
                <div className='wh-botton-content'>
                  <img src="/images/logo.png" alt="logo" />
                  <h3>SEPM FRAMEWORK FOR YOUR <br />
                    KIDS HOLISTIC DEVELOPMENT
                  </h3>
                  <p >We have developed a unique SEPM / SEPC framework for development of the Social, Emotional, Physical & Cognitive Intelligence of a child. We believe in nurturing a child's holistic development to help them reach their full potential. When a child is socially, emotionally, physically & cognitively intelligent then he/she is one more step ahead from general kids and becomes a SUPRKID.</p>
                  <ul>
                    <li className='wht-clr-1'>Social Intelligence</li>
                    <li className='wht-clr-2'>Emotional
                      Quotient</li>
                    <li className='wht-clr-3'>Physical
                      Quotient</li>
                    <li className='wht-clr-4'>Mental
                      Quotient</li>
                  </ul>
                </div>
              </div>
              <div className='col-md-5 col-sm-5 col-12'>

              </div>
            </div>
          </div>
        </div>

      </div>
      <div className='container-fluid'>
        <div className='quotation-ld'>
          <div className='row'>
            <div className='col-md-6 col-sm-6 col-12 bdr-right bdr-bottom'>
              <div className='quotation-box box-1'>
                <h3>Social Intelligence</h3>
                <p>It is the ability to successfully build relationships and navigate social environments. Our society puts a huge emphasis on book smarts and IQ, but our relationships affect a much bigger part of our lives. Social smarts are far more important than your book smarts. Building these skills in childhood provides an important foundation for:</p>
                <ul>
                  <li>• Good Cognitive Well- Being</li>
                  <li>• Bounce back quickly from setbacks and disappointments</li>
                  <li>• Behavior towards others</li>
                  <li>• Maintain healthy friendships</li>
                  <li>• Deal with bullying in positive and appropriate ways</li>
                  <li>• Children do better at school when they are emotionally and socially aware</li>
                  <li>• Can work through negative emotions, such as sadness, anger, fear, anxiety & frustration.</li>
                </ul>
              </div>
            </div>
            <div className='col-md-6 col-sm-6 col-12 bdr-bottom'>
              <div className='quotation-box box-2'>
                <h3>Emotional Quotient</h3>
                <p>It is a new concept in the area of Education and Psychology, also known as emotional intelligence referring to a skill by which an individual is able to identify one’s own emotions, are able to pay attention and have more positive relationships, which helps them to learn empathy and compassion.<br />
                  Benefits of developing emotional quotient are:
                </p>
                <ul>
                  <li>• Can influence people.</li>
                  <li>• One develops respect.</li>
                  <li>• Improve Decision making ability.</li>
                  <li>• An individual can lead a life free of stress, strain, pressure & Deep emotions.</li>
                  <li>• Improve social skills.</li>
                  <li>• Building Self confidence.</li>
                  <li>• Helps in developing sympathy towards others.</li>
                  <li>• Improves Social Skills.</li>
                </ul>
              </div>
            </div>
            <div className='col-md-6 col-sm-6 col-12 bdr-right'>
              <div className='quotation-box box-3'>
                <h3>Physical Quotient</h3>
                <p>It is ultimately concerned with understanding our body, creating a positive relationship between our mind and body.<br />
                  Some benefits of physical activity are:
                </p>
                <ul>
                  <li>• Improve cardio respiratory fitness.</li>
                  <li>• Build strong bones and muscles, control weight.</li>
                  <li>• Reduce symptoms of anxiety and depression.</li>
                  <li>• Reduce the risk of Heart disease.</li>
                  <li>• Decrease Stress</li>
                  <li>• Increase Self Esteem & Confidence</li>
                  <li>• Better Sleep & Brain Boost</li>

                </ul>
              </div>
            </div>
            <div className='col-md-6 col-sm-6 col-12 '>
              <div className='quotation-box box-4'>
                <h3>Mental Quotient</h3>
                <p>The power to learn or retain knowledge, the ability to understand the facts and significance of your behavior. It indicates a child's “brain power” in many elements of competency, such as verbal, arithmetic, spatial, & logical thinking.
                </p>
                <ul>
                  <li>• Enhances children’s ability to concentrate.</li>
                  <li>• It stimulates children’s interest in math.</li>
                  <li>• Improves the self-confidence of the child.</li>
                  <li>• Helps with the application skills in math.</li>
                  <li>• Helps to reduce mistakes in problem-solving.</li>
                  <li>• Strongly associated with better memory skills.</li>
                  <li>• It stimulates both sides of the brain.</li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='container-fluid' id="explore_program">
        <div className='explore_program-ld'>

          <div className='explore_program-ld-title'>
            <h3>EXPLORE OUR PROGRAMS</h3>
          </div>
          <div className='explore_program-ld-carousel'>
            <Carousel responsive={responsive} autoPlay={false}>
              <div className='explore_program-ld-carousel-item item-1'>
                <div className='epimg'>
                  <img src={process.env.PUBLIC_URL + '/images/landing/pr-1.png'} alt='slide' />
                  <div className="epimg-bottom">
                    <img src={process.env.PUBLIC_URL + '/images/landing/ellipse.png'} alt='slide' />
                  </div>
                </div>

                <div className='exppr-content'>
                  <h3>Chess</h3>
                  <p>Chess is an excellent game to stimulate the brain and helps with several mental conditions.</p>
                  <Link to="/chess">Read More</Link>
                </div>

              </div>
              <div className='explore_program-ld-carousel-item item-2'>
                <div className='epimg'>
                  <img src={process.env.PUBLIC_URL + '/images/landing/pr-2.png'} alt='slide' />
                  <div className="epimg-bottom">
                    <img src={process.env.PUBLIC_URL + '/images/landing/ellipse.png'} alt='slide' />
                  </div>
                </div>
                <div className='exppr-content'>
                  <h3>Abacus</h3>
                  <p>Abacus is a simple tool used for performing rapid and functional arithmetic(Maths) calculations.</p>
                  <Link to="/abacus">Read More</Link>
                </div>
              </div>
              <div className='explore_program-ld-carousel-item item-3'>
                <div className='epimg'>
                  <img src={process.env.PUBLIC_URL + '/images/landing/pr-3.png'} alt='slide' />
                  <div className="epimg-bottom">
                    <img src={process.env.PUBLIC_URL + '/images/landing/ellipse.png'} alt='slide' />
                  </div>
                </div>
                <div className='exppr-content'>
                  <h3>Martial Arts</h3>
                  <p>Martial Arts training is a systematized armed combat which is practiced for several reasons.</p>
                  <Link to="/martial-arts">Read More</Link>
                </div>
              </div>
              <div className='explore_program-ld-carousel-item item-1'>
                <div className='epimg'>
                  <img src={process.env.PUBLIC_URL + '/images/landing/pr-1.png'} alt='slide' />
                  <div className="epimg-bottom">
                    <img src={process.env.PUBLIC_URL + '/images/landing/ellipse.png'} alt='slide' />
                  </div>
                </div>
                <div className='exppr-content'>
                  <h3>Chess</h3>
                  <p>Chess is an excellent game to stimulate the brain and helps with several mental conditions.</p>
                  <Link to="/">Read More</Link>
                </div>
              </div>
              <div className='explore_program-ld-carousel-item item-1'>
                <div className='epimg'>
                  <img src={process.env.PUBLIC_URL + '/images/landing/pr-2.png'} alt='slide' />
                  <div className="epimg-bottom">
                    <img src={process.env.PUBLIC_URL + '/images/landing/ellipse.png'} alt='slide' />
                  </div>
                </div>
                <div className='exppr-content'>
                  <h3>Chess</h3>
                  <p>Chess is an excellent game to stimulate the brain and helps with several mental conditions.</p>
                  <Link to="/">Read More</Link>
                </div>
              </div>
              <div className='explore_program-ld-carousel-item item-1'>
                <div className='epimg'>
                  <img src={process.env.PUBLIC_URL + '/images/landing/pr-3.png'} alt='slide' />
                  <div className="epimg-bottom">
                    <img src={process.env.PUBLIC_URL + '/images/landing/ellipse.png'} alt='slide' />
                  </div>
                </div>
                <div className='exppr-content'>
                  <h3>Chess</h3>
                  <p>Chess is an excellent game to stimulate the brain and helps with several mental conditions.</p>
                  <Link to="/">Read More</Link>
                </div>
              </div>

            </Carousel>
          </div>
        </div>

      </div>
      <div className='univers-sec'>
        <div className='univers-content'>
          <div className='univers-title'>
            <div className='bdt'></div>
            <h1> PROGRAM EXECUTION MODELS</h1>
            <div className='bdt'></div>
          </div>

          <div className='spk-list'>
            <div className='uni-count'>1</div>
            <div>
              <h3>Super Kid - After School Program</h3>
              <p>Maxx enrolls the kids. Kids parent’s pays directly
                for the program</p>
            </div>
          </div>
          <div className='spk-list'>
            <div className='uni-count'>2</div>
            <div>

              <h3>Super Kid - In School Program</h3>
              <p>School Promotes our programs internally and pays
                for the entire program</p>
            </div>
          </div>



        </div>
      </div>
    </div>
  )
}

export default Landing