import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BannerForm from '../components/BannerForm';
const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
const Home = () => {

  return (
    <div className="page-wrapper">
   
    
    <div className="banner">
    <BannerForm/>
      <div className="banner-text">
        <h3>LET
          YOUR
          KID’S
          INNER
          GENIUS
           <span> BLOOM</span>
        </h3>
      </div>
      <div className="banner-img">
        <img src="/images/banner.png" alt="banner" />
      </div>
    </div>
    <section className="whatuget">
      <div className="whatuget-content">
        <div className="top-left-border" />
        <div className="whatuget-content-left">
          <h1>join India’s smartest</h1>
          <h3>Abacus Program</h3>
          <p>Suprkid Abacus Program is designed to ensure children are quick and confident in math problem solving. we provide the best, result oriented and child friendly abacus training program, which is designed for kids to understand and learn mathematics fun way. 
          </p>
          <p>Our mentors will ensure that your child will learn to do quick calculations mentally and especially ones with a mathematical edge </p>
          <div className="what-u-getlist mt20 ">
            <h3 className="text-left font2 mt10 wtg">WHAT YOU GET?</h3>
            <ul>
              <li>Abacus Joining Kit</li>
              <li>Live Coach / Mentor</li>
              <li>Live Online Sessions</li>
              <li>Assessments </li>
              <li>Certification</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="whatuget-middle">
        <div className="top-middle-border" />
        <img src="/images/mind-1.png" alt=''/>
      </div>
      <div className="whatuget-img">
        <div className="top-right-border" />
        <div className="box-img">
          <img src="/images/child-1.png" alt='' />
        </div>
      </div>
    </section>
    <section className="const-section">
      <div className="side-image top50">
        <img src="/images/design-elements.png" alt="" />
      </div>
      <div className="constation-row row">
        <div className="col-lg-4 col-md-12 const-first">
          <h3 className="mb15">What will kids learn ?</h3>
          <ul>
            <li>
              <img src="/images/c-icon-1.png" alt="icon" />
              <p>Calculation on Soroban (Abacus Tool)</p>
            </li>
            <li>
              <img src="/images/c-icon-2.png" alt="icon" />
              <p>Addition &amp; Subtraction on Soroban</p>
            </li>
            <li>
              <img src="/images/c-icon-3.png" alt="icon" />
              <p>Mental Calculation: Addition, Subtraction and Multiplication</p>
            </li>
            <li>
              <img src="/images/c-icon-4.png" alt="icon" />
              <p>Calculations of 1/2/3  digits (Age: 4 to 14 yrs.)                          
              </p>
            </li>
          </ul>
        </div>
        <div className="col-lg-4 col-md-12 const-middle">
          <div className="quick-box">
            <div className="top-image">
              <img src="/images/red-eli.png" alt="" />
            </div>
            <div className="middle-image">
              <img src="/images/Typo.png" alt="" />
            </div>
            <div className="bottom-image">
              <img src="/images/kidpIcture.png" alt="" />
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-12 const-last">
          <div className="program-box">
            <h3 className="mb15">Program Benefits:</h3>
            <ul>
              <li>Makes your child smarter &amp; sharp</li>
              <li>Increased concentration</li>
              <li>Higher IQ</li>
              <li>Emotional stability</li>
              <li>Enhanced creativity</li>
              <li>Greater self-confidence</li>
              <li>Better intuition </li>
              <li>Improvement in photographic memory</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="consultation-wrapper">
      <h3 className="mb40">How would Suprkid Abacus Program help your Kids:</h3>
      <div className="help-row">
        <div className="help-box">
          <p>Develop perfection in foundational mathematical operations such as addition, subtraction, division and
            multiplication.</p>
        </div>
        <div className="help-box">
          <p>Tremendous improvement in the ability to calculate, both on the abacus and mentally</p>
        </div>
        <div className="help-box">
          <p>Improves lifelong brain skills such as Concentration, Mathematics, Creativity, Listening,
            Visualization, and Photo Memory</p>
        </div>
        <div className="help-box">
          <p>Discover and bring out the hidden potential of children</p>
        </div>
        <div className="help-box">
          <p>Remove the fear of mathematics</p>
        </div>
      </div>
    </section>
    
    <section className="portfolio-box">
      <h3 className="mb30">Glimpse of Abacus batch</h3>
      <div className="portfolio">
      <Carousel responsive={responsive}>
      <div>
              <img src={process.env.PUBLIC_URL + '/images/pf-1.jpg'} alt='slide' />
             
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/pf-2.jpg'} alt='slide' />
             
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/pf-3.jpg'} alt='slide' />
             
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/pf-1.jpg'} alt='slide' />
             
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/pf-2.jpg'} alt='slide' />
             
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/pf-3.jpg'} alt='slide' />
             
            </div>

</Carousel>
      
      </div>
       
    </section>
    <div className='kid_section'>
        <div className='container'>
          <div className='kid_image'>
            <img src={process.env.PUBLIC_URL + "/images/kid.png"} alt="kid" className='img-resp'/>
          </div>
        </div>

      </div>
  </div>
  )
}

export default Home