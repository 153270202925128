import React from 'react'
import Footer from '../components/Footer'
const LandingLayout = ({children}) => {
  return (
    <div>
        {children}
        <div className='kid_section'>
        <div className='container'>
          <div className='kid_image'>
            <img src={process.env.PUBLIC_URL + "/images/kid.png"} alt="kid" className='img-resp'/>
          </div>
        </div>

      </div>
        <Footer/>
    </div>
  )
}

export default LandingLayout