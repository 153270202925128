import React, {useState} from 'react'
import { Formik } from 'formik';
import swal from 'sweetalert';
const BannerForm = () => {
    const [isLoading, setLoading] = useState(false);
    const submitRequest = async(values, {resetForm})=>{
        setLoading(true)
        const rawResponse = await fetch('https://suprkidapi.articloo.com/api/booking/submit', {
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json'
         },
         body: JSON.stringify(values)
       });
       const res = await rawResponse.json();
        if(res && res.status === true){
            setLoading(false);
            resetForm();
            swal("Thank You!", res.message, "success");
        }else{
            setLoading(false)
            swal("Sorry!", 'Something went wrong.', "error");
           }
      
     }
  return (
    <div className="form-wrapper">
    <h3>Get Free Trial</h3>
    <Formik
       initialValues={{ name: '', kid_name:'', kid_age:'', mobile_number:'', email:'' }}
       validate={values => {
         const errors = {};
         if (!values.name) {
           errors.name = 'Name is required';
         }
         if (!values.kid_name) {
           errors.kid_name = 'Kid name is required';
         }
         if (!values.kid_age) {
           errors.kid_age = 'Kid age is required';
         }
         if (!values.mobile_number) {
           errors.mobile_number = 'Mobile number is required';
         }
         if (!values.email) {
           errors.email = 'Email is required';
         }
         return errors;
       }}
       onSubmit={submitRequest}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (
         <form onSubmit={isLoading ? undefined : handleSubmit}>
        <div className="form-group">
            <input type="text" 
            className="form-control" 
            placeholder="Name" 
            name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
            {errors.name && touched.name && <span className='help-error'>{errors.name}</span>}
        </div>
        <div className="form-group">
            <input type="text" 
            className="form-control" 
            placeholder="Kid’s Name" 
            name="kid_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.kid_name}
            />
             {errors.kid_name && touched.kid_name && <span className='help-error'>{errors.kid_name}</span>}
        </div>
        <div className="form-group">
            <input type="text" 
            className="form-control" 
            placeholder="Kid’s Age"
            name="kid_age"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.kid_age}
            />
             {errors.kid_age && touched.kid_age && <span className='help-error'>{errors.kid_age}</span>}
        </div>
        <div className="form-group">
            <input type="text" 
            className="form-control" 
            placeholder="Mobile Number" 
            name="mobile_number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.mobile_number}
            />
             {errors.mobile_number && touched.mobile_number && <span className='help-error'>{errors.mobile_number}</span>}
        </div>
        <div className="form-group">
            <input type="text" 
            className="form-control" 
            placeholder="Email Address" 
            name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
             {errors.email && touched.email && <span className='help-error'>{errors.email}</span>}
        </div>
        <div className="text-center">
            <button type="submit" className="btn btn-primary">{isLoading ? 'Please wait' : 'Book Now' }</button>
        </div>
    </form>)}
     </Formik>
</div>
  )
}

export default BannerForm