import { Fragment } from 'react'
import MainLayout from './layouts/MainLayout'
import LandingLayout from './layouts/LandingLayout'
import Landing from './pages/Landing'
import Home from './pages/Home';
import RubikCube from './pages/RubikCube'
import Chess from './pages/Chess'
import MartialArts from './pages/MartialArts';
import ContactUs from './pages/ContactUs';
import ScrollToTop from './components/ScrollToTop';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Fragment>
      <Router>
      <ScrollToTop/>
        <Switch>
          <Route path={["/abacus", "/rubik-cube", "/chess", "/martial-arts", "/contact-us"]}>
            <MainLayout>
              <Switch>
                <Route exact path="/abacus" component={Home} />
                <Route exact path="/rubik-cube" component={RubikCube} />
                <Route exact path="/chess" component={Chess} />
                <Route exact path="/martial-arts" component={MartialArts} />
                <Route exact path="/contact-us" component={ContactUs} />
              </Switch>
            </MainLayout>
          </Route>
          <Route >
            <LandingLayout>
              <Switch>
                <Route path='/' exact component={Landing} />
              </Switch>
            </LandingLayout>
          </Route>
        </Switch>
      </Router>
    </Fragment>
  );
}

export default App;
